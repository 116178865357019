import {
  useYBuzzCoreCommunities,
  useYBuzzCoreCreatePost,
} from "../../../generated.ts";
import { ICreatePost } from "../../../api/types.ts";
import { useState, useCallback, useEffect } from "react";
import { useWaitForTransaction } from "wagmi";
import { Address, zeroAddress } from "viem";

const initialCreatePostData: ICreatePost = {
  community: zeroAddress,
  title: zeroAddress,
  teaser: "",
  more: "",
  morePaywallToken: zeroAddress,
  morePaywallAmount: 1n,
  author: zeroAddress,
};

export const useCreatePost = (community: Address) => {
  const [createPostData, setCreatePostData] = useState<ICreatePost>({
    ...initialCreatePostData,
    community,
  });

  const { title, teaser, more, morePaywallToken, morePaywallAmount, author } = createPostData;

  const {
    data: communityData,
    refetch: refetchCommunities,
    isLoading: isLoadingCommunities,
    isSuccess: isSuccessLoadingCommunities,
  } = useYBuzzCoreCommunities({
    args: [community],
    enabled: community && community !== zeroAddress,
  });

  const postPrice =
    (communityData && communityData.length > 5 && communityData[5]) || 0n;

  const {
    writeAsync: executeCreatePost,
    data: postCreatedData,
    error: createPostWriteError,
    isError: createPostIsError,
    isLoading: createPostIsLoading,
    isSuccess: createPostIsSuccess,
    reset: resetCreatePost,
  } = useYBuzzCoreCreatePost();

  const {
    isSuccess: waitForTransactionIsSuccess,
    isLoading: waitForTransactionIsLoading,
    refetch: refetchWaitForTransaction,
  } = useWaitForTransaction({
    hash: postCreatedData?.hash,
    enabled:
      !createPostIsLoading &&
      createPostIsSuccess &&
      postCreatedData !== undefined,
  });

  const createPost = useCallback((newCreatePostData: ICreatePost) => {
    setCreatePostData(newCreatePostData);
  }, []);

  const reset = useCallback(() => {
    setTimeout(() => {
      resetCreatePost();
    }, 2000);
    setCreatePostData(initialCreatePostData);
  }, [resetCreatePost]);

  useEffect(() => {
    if (
      executeCreatePost &&
      community !== zeroAddress &&
      title != "" &&
      teaser != "" &&
      !createPostIsLoading &&
      !isLoadingCommunities &&
      isSuccessLoadingCommunities &&
      !createPostIsSuccess &&
      !waitForTransactionIsLoading &&
      !waitForTransactionIsSuccess &&
      postCreatedData === undefined
    ) {
      executeCreatePost({
        args: [community, title, teaser, more, morePaywallToken, morePaywallAmount],
        value: postPrice,
      }).then();
    }
  }, [
    executeCreatePost,
    community,
    title,
    teaser,
    createPostIsLoading,
    createPostIsSuccess,
    isLoadingCommunities,
    isSuccessLoadingCommunities,
    more,
    morePaywallToken,
    morePaywallAmount,
    postPrice,
    waitForTransactionIsLoading,
    waitForTransactionIsSuccess,
    postCreatedData,
  ]);

  useEffect(() => {
    if (createPostIsSuccess && postCreatedData) {
      refetchWaitForTransaction().then();
    }

    if (waitForTransactionIsSuccess || createPostIsError) {
      reset();
    }
  }, [
    createPostData,
    createPostIsSuccess,
    postCreatedData,
    reset,
    createPostIsError,
    waitForTransactionIsSuccess,
    refetchWaitForTransaction,
  ]);

  return {
    createdPostId: postCreatedData ? postCreatedData.hash : null,
    isLoading: createPostIsLoading || waitForTransactionIsLoading,
    isSuccess: createPostIsSuccess && waitForTransactionIsSuccess,
    createPost,
    isError: createPostIsError,
    error: createPostWriteError,
    postPrice,
  };
};
