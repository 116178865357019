import {
  useContractRead,
  UseContractReadConfig,
  useContractWrite,
  UseContractWriteConfig,
  usePrepareContractWrite,
  UsePrepareContractWriteConfig,
  useContractEvent,
  UseContractEventConfig,
} from 'wagmi'
import {
  ReadContractResult,
  WriteContractMode,
  PrepareWriteContractResult,
} from 'wagmi/actions'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// YBuzzCore
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const yBuzzCoreABI = [
  { type: 'error', inputs: [], name: 'CallError' },
  { type: 'error', inputs: [], name: 'Overflow' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousAdmin',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'newAdmin',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'AdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'spender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'value',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'beacon',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'BeaconUpgraded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'contentId',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'community',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'postId',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'parentContentId',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: false,
      },
      {
        name: 'teaser',
        internalType: 'string',
        type: 'string',
        indexed: false,
      },
      { name: 'more', internalType: 'string', type: 'string', indexed: false },
      {
        name: 'morePaywallToken',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'morePaywallAmount',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'author',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'CommentCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'contentId',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'author',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'CommentHidden',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'name', internalType: 'string', type: 'string', indexed: false },
      {
        name: 'communityToken',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'CommunityRegistered',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'newName',
        internalType: 'string',
        type: 'string',
        indexed: false,
      },
      {
        name: 'communityToken',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'CommunityRenamed',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'contentId',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'sender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'ContentReveal',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'version', internalType: 'uint8', type: 'uint8', indexed: false },
    ],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'newOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'contentId',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'community',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      { name: 'title', internalType: 'string', type: 'string', indexed: false },
      {
        name: 'teaser',
        internalType: 'string',
        type: 'string',
        indexed: false,
      },
      { name: 'more', internalType: 'string', type: 'string', indexed: false },
      {
        name: 'morePaywallToken',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'morePaywallAmount',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'author',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'PostCreated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'contentId',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'author',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'PostHidden',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'contentId',
        internalType: 'bytes32',
        type: 'bytes32',
        indexed: true,
      },
      {
        name: 'token',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'amount',
        internalType: 'uint32',
        type: 'uint32',
        indexed: false,
      },
      {
        name: 'donator',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'ReactionSent',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      {
        name: 'value',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'Transfer',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'implementation',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'Upgraded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'sender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'costs',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      {
        name: 'yBuzzLevel',
        internalType: 'uint32',
        type: 'uint32',
        indexed: false,
      },
    ],
    name: 'YBuzzLevelUpdated',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'sender',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'amount',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'YBuzzMint',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'addressToName',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'spender', internalType: 'address', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'communities',
    outputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'membershipThreshold', internalType: 'uint256', type: 'uint256' },
      { name: 'commentThreshold', internalType: 'uint256', type: 'uint256' },
      { name: 'postThreshold', internalType: 'uint256', type: 'uint256' },
      { name: 'commentPrice', internalType: 'uint256', type: 'uint256' },
      { name: 'postPrice', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'string', type: 'string' }],
    name: 'communityNameToAddress',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
    name: 'contentAuthor',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'community', internalType: 'address payable', type: 'address' },
      { name: 'postId', internalType: 'bytes32', type: 'bytes32' },
      { name: 'parentContentId', internalType: 'bytes32', type: 'bytes32' },
      { name: 'teaser', internalType: 'string', type: 'string' },
      { name: 'more', internalType: 'string', type: 'string' },
      {
        name: 'morePaywallToken',
        internalType: 'address payable',
        type: 'address',
      },
      { name: 'morePaywallAmount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'createComment',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      {
        name: 'tokenParams',
        internalType: 'struct YBuzzTokenParams',
        type: 'tuple',
        components: [
          { name: 'name', internalType: 'string', type: 'string' },
          { name: 'symbol', internalType: 'string', type: 'string' },
          { name: 'basePrice', internalType: 'uint256', type: 'uint256' },
          { name: 'factor', internalType: 'uint256', type: 'uint256' },
          { name: 'exponent', internalType: 'uint256', type: 'uint256' },
          { name: 'maxSupply', internalType: 'uint256', type: 'uint256' },
          { name: 'maxPerTx', internalType: 'uint256', type: 'uint256' },
        ],
      },
      {
        name: 'communitySettings',
        internalType: 'struct YBuzzCommunitySettings',
        type: 'tuple',
        components: [
          { name: 'owner', internalType: 'address', type: 'address' },
          {
            name: 'membershipThreshold',
            internalType: 'uint256',
            type: 'uint256',
          },
          {
            name: 'commentThreshold',
            internalType: 'uint256',
            type: 'uint256',
          },
          { name: 'postThreshold', internalType: 'uint256', type: 'uint256' },
          { name: 'commentPrice', internalType: 'uint256', type: 'uint256' },
          { name: 'postPrice', internalType: 'uint256', type: 'uint256' },
        ],
      },
      { name: 'premintAmount', internalType: 'uint256', type: 'uint256' },
      { name: 'referrer', internalType: 'address', type: 'address' },
      { name: 'refShare', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'createCommunity',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      {
        name: 'tokenParams',
        internalType: 'struct YBuzzTokenParams',
        type: 'tuple',
        components: [
          { name: 'name', internalType: 'string', type: 'string' },
          { name: 'symbol', internalType: 'string', type: 'string' },
          { name: 'basePrice', internalType: 'uint256', type: 'uint256' },
          { name: 'factor', internalType: 'uint256', type: 'uint256' },
          { name: 'exponent', internalType: 'uint256', type: 'uint256' },
          { name: 'maxSupply', internalType: 'uint256', type: 'uint256' },
          { name: 'maxPerTx', internalType: 'uint256', type: 'uint256' },
        ],
      },
      {
        name: 'communitySettings',
        internalType: 'struct YBuzzCommunitySettings',
        type: 'tuple',
        components: [
          { name: 'owner', internalType: 'address', type: 'address' },
          {
            name: 'membershipThreshold',
            internalType: 'uint256',
            type: 'uint256',
          },
          {
            name: 'commentThreshold',
            internalType: 'uint256',
            type: 'uint256',
          },
          { name: 'postThreshold', internalType: 'uint256', type: 'uint256' },
          { name: 'commentPrice', internalType: 'uint256', type: 'uint256' },
          { name: 'postPrice', internalType: 'uint256', type: 'uint256' },
        ],
      },
      { name: 'premintAmount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'createCommunity',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'createCommunityWhitelist',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'community', internalType: 'address payable', type: 'address' },
      { name: 'title', internalType: 'string', type: 'string' },
      { name: 'teaser', internalType: 'string', type: 'string' },
      { name: 'more', internalType: 'string', type: 'string' },
      {
        name: 'morePaywallToken',
        internalType: 'address payable',
        type: 'address',
      },
      { name: 'morePaywallAmount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'createPost',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'creatorFeePPM',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'subtractedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getLevelUpCosts',
    outputs: [{ name: '_costs', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'contentId', internalType: 'bytes32', type: 'bytes32' }],
    name: 'hideComment',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'contentId', internalType: 'bytes32', type: 'bytes32' }],
    name: 'hidePost',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', internalType: 'address', type: 'address' },
      { name: 'addedValue', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      {
        name: '_communityTokenFactory',
        internalType: 'contract YBuzzCommunityTokenFactory',
        type: 'address',
      },
    ],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'levelUp',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'string', type: 'string' }],
    name: 'nameToAddress',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'nonce',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'platformFeePPM',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'platformFeeReceiver',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'contentId', internalType: 'bytes32', type: 'bytes32' },
      { name: 'token', internalType: 'address payable', type: 'address' },
      { name: 'amount', internalType: 'uint32', type: 'uint32' },
    ],
    name: 'reaction',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'referralShares',
    outputs: [
      { name: 'referrer', internalType: 'address', type: 'address' },
      { name: 'revShare', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'community', internalType: 'address payable', type: 'address' },
      { name: 'newName', internalType: 'string', type: 'string' },
    ],
    name: 'renameCommunity',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [{ name: 'contentId', internalType: 'bytes32', type: 'bytes32' }],
    name: 'reveal',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
    name: 'revealTerms',
    outputs: [
      { name: 'recipient', internalType: 'address', type: 'address' },
      {
        name: 'tokenAddress',
        internalType: 'address payable',
        type: 'address',
      },
      { name: 'amout', internalType: 'uint256', type: 'uint256' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'updateAddresses', internalType: 'address[]', type: 'address[]' },
      { name: 'allowed', internalType: 'bool', type: 'bool' },
    ],
    name: 'updateCreateCommunitiesWhitelist',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
    ],
    name: 'upgradeTo',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'yBuzzLevel',
    outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
  },
] as const

export const yBuzzCoreAddress =
  '0xA09eF8a2b201220f2A8fE79ebc00F955d0Ff1852' as const

export const yBuzzCoreConfig = {
  address: yBuzzCoreAddress,
  abi: yBuzzCoreABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// YBuzzMememojiTemplate
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const yBuzzMememojiTemplateABI = [
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalMinted',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'supply', internalType: 'uint256', type: 'uint256' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getMintPriceInclFees',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'supply', internalType: 'uint256', type: 'uint256' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getSellPriceInclFees',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'mint',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
      { name: 'minPrice', internalType: 'uint256', type: 'uint256' },
      { name: 'deadline', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'sell',
    outputs: [],
  },
] as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// YBuzzReferrer
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const yBuzzReferrerABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [{ name: 'coreAddress', internalType: 'address', type: 'address' }],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'newOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'OwnershipTransferred',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'referralCode', internalType: 'bytes32', type: 'bytes32' },
      { name: 'signature', internalType: 'bytes', type: 'bytes' },
      {
        name: 'tokenParams',
        internalType: 'struct YBuzzTokenParams',
        type: 'tuple',
        components: [
          { name: 'name', internalType: 'string', type: 'string' },
          { name: 'symbol', internalType: 'string', type: 'string' },
          { name: 'basePrice', internalType: 'uint96', type: 'uint96' },
          { name: 'factor', internalType: 'uint96', type: 'uint96' },
          { name: 'exponent', internalType: 'uint8', type: 'uint8' },
          { name: 'maxSupply', internalType: 'uint32', type: 'uint32' },
          { name: 'maxPerTx', internalType: 'uint32', type: 'uint32' },
        ],
      },
      {
        name: 'communitySettings',
        internalType: 'struct YBuzzCommunitySettings',
        type: 'tuple',
        components: [
          { name: 'owner', internalType: 'address', type: 'address' },
          {
            name: 'membershipThreshold',
            internalType: 'uint256',
            type: 'uint256',
          },
          {
            name: 'commentThreshold',
            internalType: 'uint256',
            type: 'uint256',
          },
          { name: 'postThreshold', internalType: 'uint256', type: 'uint256' },
          { name: 'commentPrice', internalType: 'uint256', type: 'uint256' },
          { name: 'postPrice', internalType: 'uint256', type: 'uint256' },
        ],
      },
      { name: 'premintAmount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'acceptReferral',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'core',
    outputs: [
      { name: '', internalType: 'contract YBuzzCore', type: 'address' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'referrerRevSharePPM',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'referrer', internalType: 'address[]', type: 'address[]' },
      { name: 'refShare', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'updateReferrer',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
    name: 'usedReferralCodes',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'withdraw',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'tokenAddress', internalType: 'address', type: 'address' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'withdrawToken',
    outputs: [],
  },
  { stateMutability: 'payable', type: 'receive' },
] as const

export const yBuzzReferrerAddress =
  '0xA7500063B9bD9f9A26A3D4a6Ba0CBf06d3fDA2ea' as const

export const yBuzzReferrerConfig = {
  address: yBuzzReferrerAddress,
  abi: yBuzzReferrerABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// YBuzzUsernames
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const yBuzzUsernamesABI = [
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousAdmin',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      {
        name: 'newAdmin',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
    ],
    name: 'AdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'beacon',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'BeaconUpgraded',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'version', internalType: 'uint8', type: 'uint8', indexed: false },
    ],
    name: 'Initialized',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'name', internalType: 'string', type: 'string', indexed: false },
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: false,
      },
      { name: 'isOwner', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'NameRegistered',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'name', internalType: 'string', type: 'string', indexed: false },
    ],
    name: 'NameUnregistered',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'newOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'implementation',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'Upgraded',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'addressToName',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'addresses', internalType: 'address[]', type: 'address[]' },
    ],
    name: 'getNames',
    outputs: [{ name: '', internalType: 'string[]', type: 'string[]' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'initialize',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'string', type: 'string' }],
    name: 'nameToAddress',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'name', internalType: 'string', type: 'string' }],
    name: 'registerName',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'name', internalType: 'string', type: 'string' },
      { name: 'user', internalType: 'address', type: 'address' },
    ],
    name: 'registerNameAsOwner',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
    ],
    name: 'upgradeTo',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'newImplementation', internalType: 'address', type: 'address' },
      { name: 'data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
  },
] as const

export const yBuzzUsernamesAddress =
  '0x758432fAce79e72a9Dac530a4EDEDb2E3C2bbd5E' as const

export const yBuzzUsernamesConfig = {
  address: yBuzzUsernamesAddress,
  abi: yBuzzUsernamesABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// React
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__.
 */
export function useYBuzzCoreRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"addressToName"`.
 */
export function useYBuzzCoreAddressToName<
  TFunctionName extends 'addressToName',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'addressToName',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"allowance"`.
 */
export function useYBuzzCoreAllowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useYBuzzCoreBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"communities"`.
 */
export function useYBuzzCoreCommunities<
  TFunctionName extends 'communities',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'communities',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"communityNameToAddress"`.
 */
export function useYBuzzCoreCommunityNameToAddress<
  TFunctionName extends 'communityNameToAddress',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'communityNameToAddress',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"contentAuthor"`.
 */
export function useYBuzzCoreContentAuthor<
  TFunctionName extends 'contentAuthor',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'contentAuthor',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"createCommunityWhitelist"`.
 */
export function useYBuzzCoreCreateCommunityWhitelist<
  TFunctionName extends 'createCommunityWhitelist',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'createCommunityWhitelist',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"creatorFeePPM"`.
 */
export function useYBuzzCoreCreatorFeePpm<
  TFunctionName extends 'creatorFeePPM',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'creatorFeePPM',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"decimals"`.
 */
export function useYBuzzCoreDecimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"getLevelUpCosts"`.
 */
export function useYBuzzCoreGetLevelUpCosts<
  TFunctionName extends 'getLevelUpCosts',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'getLevelUpCosts',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"name"`.
 */
export function useYBuzzCoreName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"nameToAddress"`.
 */
export function useYBuzzCoreNameToAddress<
  TFunctionName extends 'nameToAddress',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'nameToAddress',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"nonce"`.
 */
export function useYBuzzCoreNonce<
  TFunctionName extends 'nonce',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'nonce',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"owner"`.
 */
export function useYBuzzCoreOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"platformFeePPM"`.
 */
export function useYBuzzCorePlatformFeePpm<
  TFunctionName extends 'platformFeePPM',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'platformFeePPM',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"platformFeeReceiver"`.
 */
export function useYBuzzCorePlatformFeeReceiver<
  TFunctionName extends 'platformFeeReceiver',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'platformFeeReceiver',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"proxiableUUID"`.
 */
export function useYBuzzCoreProxiableUuid<
  TFunctionName extends 'proxiableUUID',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'proxiableUUID',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"referralShares"`.
 */
export function useYBuzzCoreReferralShares<
  TFunctionName extends 'referralShares',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'referralShares',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"revealTerms"`.
 */
export function useYBuzzCoreRevealTerms<
  TFunctionName extends 'revealTerms',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'revealTerms',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"symbol"`.
 */
export function useYBuzzCoreSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useYBuzzCoreTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"yBuzzLevel"`.
 */
export function useYBuzzCoreYBuzzLevel<
  TFunctionName extends 'yBuzzLevel',
  TSelectData = ReadContractResult<typeof yBuzzCoreABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'yBuzzLevel',
    ...config,
  } as UseContractReadConfig<typeof yBuzzCoreABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__.
 */
export function useYBuzzCoreWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof yBuzzCoreABI, TFunctionName, TMode> & {
        abi?: never
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzCoreABI, TFunctionName, TMode>({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"approve"`.
 */
export function useYBuzzCoreApprove<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          'approve'
        >['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof yBuzzCoreABI, 'approve', TMode> & {
        abi?: never
        functionName?: 'approve'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzCoreABI, 'approve', TMode>({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"createComment"`.
 */
export function useYBuzzCoreCreateComment<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          'createComment'
        >['request']['abi'],
        'createComment',
        TMode
      > & { functionName?: 'createComment' }
    : UseContractWriteConfig<typeof yBuzzCoreABI, 'createComment', TMode> & {
        abi?: never
        functionName?: 'createComment'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzCoreABI, 'createComment', TMode>({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'createComment',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"createCommunity"`.
 */
export function useYBuzzCoreCreateCommunity<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          'createCommunity'
        >['request']['abi'],
        'createCommunity',
        TMode
      > & { functionName?: 'createCommunity' }
    : UseContractWriteConfig<typeof yBuzzCoreABI, 'createCommunity', TMode> & {
        abi?: never
        functionName?: 'createCommunity'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzCoreABI, 'createCommunity', TMode>({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'createCommunity',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"createPost"`.
 */
export function useYBuzzCoreCreatePost<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          'createPost'
        >['request']['abi'],
        'createPost',
        TMode
      > & { functionName?: 'createPost' }
    : UseContractWriteConfig<typeof yBuzzCoreABI, 'createPost', TMode> & {
        abi?: never
        functionName?: 'createPost'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzCoreABI, 'createPost', TMode>({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'createPost',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function useYBuzzCoreDecreaseAllowance<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          'decreaseAllowance'
        >['request']['abi'],
        'decreaseAllowance',
        TMode
      > & { functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<
        typeof yBuzzCoreABI,
        'decreaseAllowance',
        TMode
      > & {
        abi?: never
        functionName?: 'decreaseAllowance'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzCoreABI, 'decreaseAllowance', TMode>({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'decreaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"hideComment"`.
 */
export function useYBuzzCoreHideComment<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          'hideComment'
        >['request']['abi'],
        'hideComment',
        TMode
      > & { functionName?: 'hideComment' }
    : UseContractWriteConfig<typeof yBuzzCoreABI, 'hideComment', TMode> & {
        abi?: never
        functionName?: 'hideComment'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzCoreABI, 'hideComment', TMode>({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'hideComment',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"hidePost"`.
 */
export function useYBuzzCoreHidePost<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          'hidePost'
        >['request']['abi'],
        'hidePost',
        TMode
      > & { functionName?: 'hidePost' }
    : UseContractWriteConfig<typeof yBuzzCoreABI, 'hidePost', TMode> & {
        abi?: never
        functionName?: 'hidePost'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzCoreABI, 'hidePost', TMode>({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'hidePost',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function useYBuzzCoreIncreaseAllowance<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          'increaseAllowance'
        >['request']['abi'],
        'increaseAllowance',
        TMode
      > & { functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<
        typeof yBuzzCoreABI,
        'increaseAllowance',
        TMode
      > & {
        abi?: never
        functionName?: 'increaseAllowance'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzCoreABI, 'increaseAllowance', TMode>({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'increaseAllowance',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"initialize"`.
 */
export function useYBuzzCoreInitialize<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          'initialize'
        >['request']['abi'],
        'initialize',
        TMode
      > & { functionName?: 'initialize' }
    : UseContractWriteConfig<typeof yBuzzCoreABI, 'initialize', TMode> & {
        abi?: never
        functionName?: 'initialize'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzCoreABI, 'initialize', TMode>({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"levelUp"`.
 */
export function useYBuzzCoreLevelUp<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          'levelUp'
        >['request']['abi'],
        'levelUp',
        TMode
      > & { functionName?: 'levelUp' }
    : UseContractWriteConfig<typeof yBuzzCoreABI, 'levelUp', TMode> & {
        abi?: never
        functionName?: 'levelUp'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzCoreABI, 'levelUp', TMode>({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'levelUp',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"reaction"`.
 */
export function useYBuzzCoreReaction<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          'reaction'
        >['request']['abi'],
        'reaction',
        TMode
      > & { functionName?: 'reaction' }
    : UseContractWriteConfig<typeof yBuzzCoreABI, 'reaction', TMode> & {
        abi?: never
        functionName?: 'reaction'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzCoreABI, 'reaction', TMode>({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'reaction',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"renameCommunity"`.
 */
export function useYBuzzCoreRenameCommunity<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          'renameCommunity'
        >['request']['abi'],
        'renameCommunity',
        TMode
      > & { functionName?: 'renameCommunity' }
    : UseContractWriteConfig<typeof yBuzzCoreABI, 'renameCommunity', TMode> & {
        abi?: never
        functionName?: 'renameCommunity'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzCoreABI, 'renameCommunity', TMode>({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'renameCommunity',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function useYBuzzCoreRenounceOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          'renounceOwnership'
        >['request']['abi'],
        'renounceOwnership',
        TMode
      > & { functionName?: 'renounceOwnership' }
    : UseContractWriteConfig<
        typeof yBuzzCoreABI,
        'renounceOwnership',
        TMode
      > & {
        abi?: never
        functionName?: 'renounceOwnership'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzCoreABI, 'renounceOwnership', TMode>({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'renounceOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"reveal"`.
 */
export function useYBuzzCoreReveal<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          'reveal'
        >['request']['abi'],
        'reveal',
        TMode
      > & { functionName?: 'reveal' }
    : UseContractWriteConfig<typeof yBuzzCoreABI, 'reveal', TMode> & {
        abi?: never
        functionName?: 'reveal'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzCoreABI, 'reveal', TMode>({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'reveal',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"transfer"`.
 */
export function useYBuzzCoreTransfer<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          'transfer'
        >['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof yBuzzCoreABI, 'transfer', TMode> & {
        abi?: never
        functionName?: 'transfer'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzCoreABI, 'transfer', TMode>({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'transfer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useYBuzzCoreTransferFrom<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof yBuzzCoreABI, 'transferFrom', TMode> & {
        abi?: never
        functionName?: 'transferFrom'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzCoreABI, 'transferFrom', TMode>({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function useYBuzzCoreTransferOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          'transferOwnership'
        >['request']['abi'],
        'transferOwnership',
        TMode
      > & { functionName?: 'transferOwnership' }
    : UseContractWriteConfig<
        typeof yBuzzCoreABI,
        'transferOwnership',
        TMode
      > & {
        abi?: never
        functionName?: 'transferOwnership'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzCoreABI, 'transferOwnership', TMode>({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'transferOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"updateCreateCommunitiesWhitelist"`.
 */
export function useYBuzzCoreUpdateCreateCommunitiesWhitelist<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          'updateCreateCommunitiesWhitelist'
        >['request']['abi'],
        'updateCreateCommunitiesWhitelist',
        TMode
      > & { functionName?: 'updateCreateCommunitiesWhitelist' }
    : UseContractWriteConfig<
        typeof yBuzzCoreABI,
        'updateCreateCommunitiesWhitelist',
        TMode
      > & {
        abi?: never
        functionName?: 'updateCreateCommunitiesWhitelist'
      } = {} as any,
) {
  return useContractWrite<
    typeof yBuzzCoreABI,
    'updateCreateCommunitiesWhitelist',
    TMode
  >({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'updateCreateCommunitiesWhitelist',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"upgradeTo"`.
 */
export function useYBuzzCoreUpgradeTo<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          'upgradeTo'
        >['request']['abi'],
        'upgradeTo',
        TMode
      > & { functionName?: 'upgradeTo' }
    : UseContractWriteConfig<typeof yBuzzCoreABI, 'upgradeTo', TMode> & {
        abi?: never
        functionName?: 'upgradeTo'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzCoreABI, 'upgradeTo', TMode>({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'upgradeTo',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"upgradeToAndCall"`.
 */
export function useYBuzzCoreUpgradeToAndCall<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzCoreABI,
          'upgradeToAndCall'
        >['request']['abi'],
        'upgradeToAndCall',
        TMode
      > & { functionName?: 'upgradeToAndCall' }
    : UseContractWriteConfig<typeof yBuzzCoreABI, 'upgradeToAndCall', TMode> & {
        abi?: never
        functionName?: 'upgradeToAndCall'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzCoreABI, 'upgradeToAndCall', TMode>({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'upgradeToAndCall',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__.
 */
export function usePrepareYBuzzCoreWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzCoreABI, TFunctionName>,
    'abi' | 'address'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzCoreABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareYBuzzCoreApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'approve'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"createComment"`.
 */
export function usePrepareYBuzzCoreCreateComment(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'createComment'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'createComment',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'createComment'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"createCommunity"`.
 */
export function usePrepareYBuzzCoreCreateCommunity(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'createCommunity'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'createCommunity',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'createCommunity'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"createPost"`.
 */
export function usePrepareYBuzzCoreCreatePost(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'createPost'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'createPost',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'createPost'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function usePrepareYBuzzCoreDecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'decreaseAllowance'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'decreaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"hideComment"`.
 */
export function usePrepareYBuzzCoreHideComment(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'hideComment'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'hideComment',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'hideComment'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"hidePost"`.
 */
export function usePrepareYBuzzCoreHidePost(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'hidePost'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'hidePost',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'hidePost'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function usePrepareYBuzzCoreIncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'increaseAllowance'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'increaseAllowance'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"initialize"`.
 */
export function usePrepareYBuzzCoreInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'initialize'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"levelUp"`.
 */
export function usePrepareYBuzzCoreLevelUp(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'levelUp'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'levelUp',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'levelUp'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"reaction"`.
 */
export function usePrepareYBuzzCoreReaction(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'reaction'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'reaction',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'reaction'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"renameCommunity"`.
 */
export function usePrepareYBuzzCoreRenameCommunity(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'renameCommunity'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'renameCommunity',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'renameCommunity'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function usePrepareYBuzzCoreRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'renounceOwnership'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'renounceOwnership'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"reveal"`.
 */
export function usePrepareYBuzzCoreReveal(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'reveal'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'reveal',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'reveal'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareYBuzzCoreTransfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'transfer'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'transfer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareYBuzzCoreTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'transferFrom'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'transferFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function usePrepareYBuzzCoreTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'transferOwnership'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'transferOwnership'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"updateCreateCommunitiesWhitelist"`.
 */
export function usePrepareYBuzzCoreUpdateCreateCommunitiesWhitelist(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof yBuzzCoreABI,
      'updateCreateCommunitiesWhitelist'
    >,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'updateCreateCommunitiesWhitelist',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof yBuzzCoreABI,
    'updateCreateCommunitiesWhitelist'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"upgradeTo"`.
 */
export function usePrepareYBuzzCoreUpgradeTo(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'upgradeTo'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'upgradeTo',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'upgradeTo'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzCoreABI}__ and `functionName` set to `"upgradeToAndCall"`.
 */
export function usePrepareYBuzzCoreUpgradeToAndCall(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'upgradeToAndCall'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    functionName: 'upgradeToAndCall',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzCoreABI, 'upgradeToAndCall'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzCoreABI}__.
 */
export function useYBuzzCoreEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof yBuzzCoreABI, TEventName>,
    'abi' | 'address'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    ...config,
  } as UseContractEventConfig<typeof yBuzzCoreABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzCoreABI}__ and `eventName` set to `"AdminChanged"`.
 */
export function useYBuzzCoreAdminChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzCoreABI, 'AdminChanged'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    eventName: 'AdminChanged',
    ...config,
  } as UseContractEventConfig<typeof yBuzzCoreABI, 'AdminChanged'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzCoreABI}__ and `eventName` set to `"Approval"`.
 */
export function useYBuzzCoreApprovalEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzCoreABI, 'Approval'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof yBuzzCoreABI, 'Approval'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzCoreABI}__ and `eventName` set to `"BeaconUpgraded"`.
 */
export function useYBuzzCoreBeaconUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzCoreABI, 'BeaconUpgraded'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    eventName: 'BeaconUpgraded',
    ...config,
  } as UseContractEventConfig<typeof yBuzzCoreABI, 'BeaconUpgraded'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzCoreABI}__ and `eventName` set to `"CommentCreated"`.
 */
export function useYBuzzCoreCommentCreatedEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzCoreABI, 'CommentCreated'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    eventName: 'CommentCreated',
    ...config,
  } as UseContractEventConfig<typeof yBuzzCoreABI, 'CommentCreated'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzCoreABI}__ and `eventName` set to `"CommentHidden"`.
 */
export function useYBuzzCoreCommentHiddenEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzCoreABI, 'CommentHidden'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    eventName: 'CommentHidden',
    ...config,
  } as UseContractEventConfig<typeof yBuzzCoreABI, 'CommentHidden'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzCoreABI}__ and `eventName` set to `"CommunityRegistered"`.
 */
export function useYBuzzCoreCommunityRegisteredEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzCoreABI, 'CommunityRegistered'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    eventName: 'CommunityRegistered',
    ...config,
  } as UseContractEventConfig<typeof yBuzzCoreABI, 'CommunityRegistered'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzCoreABI}__ and `eventName` set to `"CommunityRenamed"`.
 */
export function useYBuzzCoreCommunityRenamedEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzCoreABI, 'CommunityRenamed'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    eventName: 'CommunityRenamed',
    ...config,
  } as UseContractEventConfig<typeof yBuzzCoreABI, 'CommunityRenamed'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzCoreABI}__ and `eventName` set to `"ContentReveal"`.
 */
export function useYBuzzCoreContentRevealEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzCoreABI, 'ContentReveal'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    eventName: 'ContentReveal',
    ...config,
  } as UseContractEventConfig<typeof yBuzzCoreABI, 'ContentReveal'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzCoreABI}__ and `eventName` set to `"Initialized"`.
 */
export function useYBuzzCoreInitializedEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzCoreABI, 'Initialized'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    eventName: 'Initialized',
    ...config,
  } as UseContractEventConfig<typeof yBuzzCoreABI, 'Initialized'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzCoreABI}__ and `eventName` set to `"OwnershipTransferred"`.
 */
export function useYBuzzCoreOwnershipTransferredEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzCoreABI, 'OwnershipTransferred'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    eventName: 'OwnershipTransferred',
    ...config,
  } as UseContractEventConfig<typeof yBuzzCoreABI, 'OwnershipTransferred'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzCoreABI}__ and `eventName` set to `"PostCreated"`.
 */
export function useYBuzzCorePostCreatedEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzCoreABI, 'PostCreated'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    eventName: 'PostCreated',
    ...config,
  } as UseContractEventConfig<typeof yBuzzCoreABI, 'PostCreated'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzCoreABI}__ and `eventName` set to `"PostHidden"`.
 */
export function useYBuzzCorePostHiddenEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzCoreABI, 'PostHidden'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    eventName: 'PostHidden',
    ...config,
  } as UseContractEventConfig<typeof yBuzzCoreABI, 'PostHidden'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzCoreABI}__ and `eventName` set to `"ReactionSent"`.
 */
export function useYBuzzCoreReactionSentEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzCoreABI, 'ReactionSent'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    eventName: 'ReactionSent',
    ...config,
  } as UseContractEventConfig<typeof yBuzzCoreABI, 'ReactionSent'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzCoreABI}__ and `eventName` set to `"Transfer"`.
 */
export function useYBuzzCoreTransferEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzCoreABI, 'Transfer'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof yBuzzCoreABI, 'Transfer'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzCoreABI}__ and `eventName` set to `"Upgraded"`.
 */
export function useYBuzzCoreUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzCoreABI, 'Upgraded'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    eventName: 'Upgraded',
    ...config,
  } as UseContractEventConfig<typeof yBuzzCoreABI, 'Upgraded'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzCoreABI}__ and `eventName` set to `"YBuzzLevelUpdated"`.
 */
export function useYBuzzCoreYBuzzLevelUpdatedEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzCoreABI, 'YBuzzLevelUpdated'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    eventName: 'YBuzzLevelUpdated',
    ...config,
  } as UseContractEventConfig<typeof yBuzzCoreABI, 'YBuzzLevelUpdated'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzCoreABI}__ and `eventName` set to `"YBuzzMint"`.
 */
export function useYBuzzCoreYBuzzMintEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzCoreABI, 'YBuzzMint'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzCoreABI,
    address: yBuzzCoreAddress,
    eventName: 'YBuzzMint',
    ...config,
  } as UseContractEventConfig<typeof yBuzzCoreABI, 'YBuzzMint'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzMememojiTemplateABI}__.
 */
export function useYBuzzMememojiTemplateRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<
    typeof yBuzzMememojiTemplateABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof yBuzzMememojiTemplateABI,
      TFunctionName,
      TSelectData
    >,
    'abi'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzMememojiTemplateABI,
    ...config,
  } as UseContractReadConfig<
    typeof yBuzzMememojiTemplateABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzMememojiTemplateABI}__ and `functionName` set to `"totalMinted"`.
 */
export function useYBuzzMememojiTemplateTotalMinted<
  TFunctionName extends 'totalMinted',
  TSelectData = ReadContractResult<
    typeof yBuzzMememojiTemplateABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof yBuzzMememojiTemplateABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzMememojiTemplateABI,
    functionName: 'totalMinted',
    ...config,
  } as UseContractReadConfig<
    typeof yBuzzMememojiTemplateABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzMememojiTemplateABI}__ and `functionName` set to `"getMintPriceInclFees"`.
 */
export function useYBuzzMememojiTemplateGetMintPriceInclFees<
  TFunctionName extends 'getMintPriceInclFees',
  TSelectData = ReadContractResult<
    typeof yBuzzMememojiTemplateABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof yBuzzMememojiTemplateABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzMememojiTemplateABI,
    functionName: 'getMintPriceInclFees',
    ...config,
  } as UseContractReadConfig<
    typeof yBuzzMememojiTemplateABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzMememojiTemplateABI}__ and `functionName` set to `"getSellPriceInclFees"`.
 */
export function useYBuzzMememojiTemplateGetSellPriceInclFees<
  TFunctionName extends 'getSellPriceInclFees',
  TSelectData = ReadContractResult<
    typeof yBuzzMememojiTemplateABI,
    TFunctionName
  >,
>(
  config: Omit<
    UseContractReadConfig<
      typeof yBuzzMememojiTemplateABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzMememojiTemplateABI,
    functionName: 'getSellPriceInclFees',
    ...config,
  } as UseContractReadConfig<
    typeof yBuzzMememojiTemplateABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzMememojiTemplateABI}__.
 */
export function useYBuzzMememojiTemplateWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzMememojiTemplateABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<
        typeof yBuzzMememojiTemplateABI,
        TFunctionName,
        TMode
      > & {
        abi?: never
      } = {} as any,
) {
  return useContractWrite<
    typeof yBuzzMememojiTemplateABI,
    TFunctionName,
    TMode
  >({ abi: yBuzzMememojiTemplateABI, ...config } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzMememojiTemplateABI}__ and `functionName` set to `"mint"`.
 */
export function useYBuzzMememojiTemplateMint<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzMememojiTemplateABI,
          'mint'
        >['request']['abi'],
        'mint',
        TMode
      > & { functionName?: 'mint' }
    : UseContractWriteConfig<typeof yBuzzMememojiTemplateABI, 'mint', TMode> & {
        abi?: never
        functionName?: 'mint'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzMememojiTemplateABI, 'mint', TMode>({
    abi: yBuzzMememojiTemplateABI,
    functionName: 'mint',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzMememojiTemplateABI}__ and `functionName` set to `"sell"`.
 */
export function useYBuzzMememojiTemplateSell<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzMememojiTemplateABI,
          'sell'
        >['request']['abi'],
        'sell',
        TMode
      > & { functionName?: 'sell' }
    : UseContractWriteConfig<typeof yBuzzMememojiTemplateABI, 'sell', TMode> & {
        abi?: never
        functionName?: 'sell'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzMememojiTemplateABI, 'sell', TMode>({
    abi: yBuzzMememojiTemplateABI,
    functionName: 'sell',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzMememojiTemplateABI}__.
 */
export function usePrepareYBuzzMememojiTemplateWrite<
  TFunctionName extends string,
>(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof yBuzzMememojiTemplateABI,
      TFunctionName
    >,
    'abi'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzMememojiTemplateABI,
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof yBuzzMememojiTemplateABI,
    TFunctionName
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzMememojiTemplateABI}__ and `functionName` set to `"mint"`.
 */
export function usePrepareYBuzzMememojiTemplateMint(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzMememojiTemplateABI, 'mint'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzMememojiTemplateABI,
    functionName: 'mint',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzMememojiTemplateABI, 'mint'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzMememojiTemplateABI}__ and `functionName` set to `"sell"`.
 */
export function usePrepareYBuzzMememojiTemplateSell(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzMememojiTemplateABI, 'sell'>,
    'abi' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzMememojiTemplateABI,
    functionName: 'sell',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzMememojiTemplateABI, 'sell'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzReferrerABI}__.
 */
export function useYBuzzReferrerRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof yBuzzReferrerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzReferrerABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    ...config,
  } as UseContractReadConfig<
    typeof yBuzzReferrerABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzReferrerABI}__ and `functionName` set to `"core"`.
 */
export function useYBuzzReferrerCore<
  TFunctionName extends 'core',
  TSelectData = ReadContractResult<typeof yBuzzReferrerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzReferrerABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    functionName: 'core',
    ...config,
  } as UseContractReadConfig<
    typeof yBuzzReferrerABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzReferrerABI}__ and `functionName` set to `"owner"`.
 */
export function useYBuzzReferrerOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof yBuzzReferrerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzReferrerABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<
    typeof yBuzzReferrerABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzReferrerABI}__ and `functionName` set to `"referrerRevSharePPM"`.
 */
export function useYBuzzReferrerReferrerRevSharePpm<
  TFunctionName extends 'referrerRevSharePPM',
  TSelectData = ReadContractResult<typeof yBuzzReferrerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzReferrerABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    functionName: 'referrerRevSharePPM',
    ...config,
  } as UseContractReadConfig<
    typeof yBuzzReferrerABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzReferrerABI}__ and `functionName` set to `"usedReferralCodes"`.
 */
export function useYBuzzReferrerUsedReferralCodes<
  TFunctionName extends 'usedReferralCodes',
  TSelectData = ReadContractResult<typeof yBuzzReferrerABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzReferrerABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    functionName: 'usedReferralCodes',
    ...config,
  } as UseContractReadConfig<
    typeof yBuzzReferrerABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzReferrerABI}__.
 */
export function useYBuzzReferrerWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzReferrerABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof yBuzzReferrerABI, TFunctionName, TMode> & {
        abi?: never
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzReferrerABI, TFunctionName, TMode>({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzReferrerABI}__ and `functionName` set to `"acceptReferral"`.
 */
export function useYBuzzReferrerAcceptReferral<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzReferrerABI,
          'acceptReferral'
        >['request']['abi'],
        'acceptReferral',
        TMode
      > & { functionName?: 'acceptReferral' }
    : UseContractWriteConfig<
        typeof yBuzzReferrerABI,
        'acceptReferral',
        TMode
      > & {
        abi?: never
        functionName?: 'acceptReferral'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzReferrerABI, 'acceptReferral', TMode>({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    functionName: 'acceptReferral',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzReferrerABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function useYBuzzReferrerRenounceOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzReferrerABI,
          'renounceOwnership'
        >['request']['abi'],
        'renounceOwnership',
        TMode
      > & { functionName?: 'renounceOwnership' }
    : UseContractWriteConfig<
        typeof yBuzzReferrerABI,
        'renounceOwnership',
        TMode
      > & {
        abi?: never
        functionName?: 'renounceOwnership'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzReferrerABI, 'renounceOwnership', TMode>({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    functionName: 'renounceOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzReferrerABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function useYBuzzReferrerTransferOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzReferrerABI,
          'transferOwnership'
        >['request']['abi'],
        'transferOwnership',
        TMode
      > & { functionName?: 'transferOwnership' }
    : UseContractWriteConfig<
        typeof yBuzzReferrerABI,
        'transferOwnership',
        TMode
      > & {
        abi?: never
        functionName?: 'transferOwnership'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzReferrerABI, 'transferOwnership', TMode>({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    functionName: 'transferOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzReferrerABI}__ and `functionName` set to `"updateReferrer"`.
 */
export function useYBuzzReferrerUpdateReferrer<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzReferrerABI,
          'updateReferrer'
        >['request']['abi'],
        'updateReferrer',
        TMode
      > & { functionName?: 'updateReferrer' }
    : UseContractWriteConfig<
        typeof yBuzzReferrerABI,
        'updateReferrer',
        TMode
      > & {
        abi?: never
        functionName?: 'updateReferrer'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzReferrerABI, 'updateReferrer', TMode>({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    functionName: 'updateReferrer',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzReferrerABI}__ and `functionName` set to `"withdraw"`.
 */
export function useYBuzzReferrerWithdraw<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzReferrerABI,
          'withdraw'
        >['request']['abi'],
        'withdraw',
        TMode
      > & { functionName?: 'withdraw' }
    : UseContractWriteConfig<typeof yBuzzReferrerABI, 'withdraw', TMode> & {
        abi?: never
        functionName?: 'withdraw'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzReferrerABI, 'withdraw', TMode>({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    functionName: 'withdraw',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzReferrerABI}__ and `functionName` set to `"withdrawToken"`.
 */
export function useYBuzzReferrerWithdrawToken<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzReferrerABI,
          'withdrawToken'
        >['request']['abi'],
        'withdrawToken',
        TMode
      > & { functionName?: 'withdrawToken' }
    : UseContractWriteConfig<
        typeof yBuzzReferrerABI,
        'withdrawToken',
        TMode
      > & {
        abi?: never
        functionName?: 'withdrawToken'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzReferrerABI, 'withdrawToken', TMode>({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    functionName: 'withdrawToken',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzReferrerABI}__.
 */
export function usePrepareYBuzzReferrerWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzReferrerABI, TFunctionName>,
    'abi' | 'address'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzReferrerABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzReferrerABI}__ and `functionName` set to `"acceptReferral"`.
 */
export function usePrepareYBuzzReferrerAcceptReferral(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzReferrerABI, 'acceptReferral'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    functionName: 'acceptReferral',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzReferrerABI, 'acceptReferral'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzReferrerABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function usePrepareYBuzzReferrerRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzReferrerABI, 'renounceOwnership'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof yBuzzReferrerABI,
    'renounceOwnership'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzReferrerABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function usePrepareYBuzzReferrerTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzReferrerABI, 'transferOwnership'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof yBuzzReferrerABI,
    'transferOwnership'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzReferrerABI}__ and `functionName` set to `"updateReferrer"`.
 */
export function usePrepareYBuzzReferrerUpdateReferrer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzReferrerABI, 'updateReferrer'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    functionName: 'updateReferrer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzReferrerABI, 'updateReferrer'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzReferrerABI}__ and `functionName` set to `"withdraw"`.
 */
export function usePrepareYBuzzReferrerWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzReferrerABI, 'withdraw'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    functionName: 'withdraw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzReferrerABI, 'withdraw'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzReferrerABI}__ and `functionName` set to `"withdrawToken"`.
 */
export function usePrepareYBuzzReferrerWithdrawToken(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzReferrerABI, 'withdrawToken'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    functionName: 'withdrawToken',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzReferrerABI, 'withdrawToken'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzReferrerABI}__.
 */
export function useYBuzzReferrerEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof yBuzzReferrerABI, TEventName>,
    'abi' | 'address'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    ...config,
  } as UseContractEventConfig<typeof yBuzzReferrerABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzReferrerABI}__ and `eventName` set to `"OwnershipTransferred"`.
 */
export function useYBuzzReferrerOwnershipTransferredEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzReferrerABI, 'OwnershipTransferred'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzReferrerABI,
    address: yBuzzReferrerAddress,
    eventName: 'OwnershipTransferred',
    ...config,
  } as UseContractEventConfig<typeof yBuzzReferrerABI, 'OwnershipTransferred'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzUsernamesABI}__.
 */
export function useYBuzzUsernamesRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof yBuzzUsernamesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzUsernamesABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    ...config,
  } as UseContractReadConfig<
    typeof yBuzzUsernamesABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `functionName` set to `"addressToName"`.
 */
export function useYBuzzUsernamesAddressToName<
  TFunctionName extends 'addressToName',
  TSelectData = ReadContractResult<typeof yBuzzUsernamesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzUsernamesABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    functionName: 'addressToName',
    ...config,
  } as UseContractReadConfig<
    typeof yBuzzUsernamesABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `functionName` set to `"getNames"`.
 */
export function useYBuzzUsernamesGetNames<
  TFunctionName extends 'getNames',
  TSelectData = ReadContractResult<typeof yBuzzUsernamesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzUsernamesABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    functionName: 'getNames',
    ...config,
  } as UseContractReadConfig<
    typeof yBuzzUsernamesABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `functionName` set to `"nameToAddress"`.
 */
export function useYBuzzUsernamesNameToAddress<
  TFunctionName extends 'nameToAddress',
  TSelectData = ReadContractResult<typeof yBuzzUsernamesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzUsernamesABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    functionName: 'nameToAddress',
    ...config,
  } as UseContractReadConfig<
    typeof yBuzzUsernamesABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `functionName` set to `"owner"`.
 */
export function useYBuzzUsernamesOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof yBuzzUsernamesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzUsernamesABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<
    typeof yBuzzUsernamesABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `functionName` set to `"proxiableUUID"`.
 */
export function useYBuzzUsernamesProxiableUuid<
  TFunctionName extends 'proxiableUUID',
  TSelectData = ReadContractResult<typeof yBuzzUsernamesABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof yBuzzUsernamesABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return useContractRead({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    functionName: 'proxiableUUID',
    ...config,
  } as UseContractReadConfig<
    typeof yBuzzUsernamesABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzUsernamesABI}__.
 */
export function useYBuzzUsernamesWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzUsernamesABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof yBuzzUsernamesABI, TFunctionName, TMode> & {
        abi?: never
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzUsernamesABI, TFunctionName, TMode>({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `functionName` set to `"initialize"`.
 */
export function useYBuzzUsernamesInitialize<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzUsernamesABI,
          'initialize'
        >['request']['abi'],
        'initialize',
        TMode
      > & { functionName?: 'initialize' }
    : UseContractWriteConfig<typeof yBuzzUsernamesABI, 'initialize', TMode> & {
        abi?: never
        functionName?: 'initialize'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzUsernamesABI, 'initialize', TMode>({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    functionName: 'initialize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `functionName` set to `"registerName"`.
 */
export function useYBuzzUsernamesRegisterName<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzUsernamesABI,
          'registerName'
        >['request']['abi'],
        'registerName',
        TMode
      > & { functionName?: 'registerName' }
    : UseContractWriteConfig<
        typeof yBuzzUsernamesABI,
        'registerName',
        TMode
      > & {
        abi?: never
        functionName?: 'registerName'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzUsernamesABI, 'registerName', TMode>({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    functionName: 'registerName',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `functionName` set to `"registerNameAsOwner"`.
 */
export function useYBuzzUsernamesRegisterNameAsOwner<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzUsernamesABI,
          'registerNameAsOwner'
        >['request']['abi'],
        'registerNameAsOwner',
        TMode
      > & { functionName?: 'registerNameAsOwner' }
    : UseContractWriteConfig<
        typeof yBuzzUsernamesABI,
        'registerNameAsOwner',
        TMode
      > & {
        abi?: never
        functionName?: 'registerNameAsOwner'
      } = {} as any,
) {
  return useContractWrite<
    typeof yBuzzUsernamesABI,
    'registerNameAsOwner',
    TMode
  >({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    functionName: 'registerNameAsOwner',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function useYBuzzUsernamesRenounceOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzUsernamesABI,
          'renounceOwnership'
        >['request']['abi'],
        'renounceOwnership',
        TMode
      > & { functionName?: 'renounceOwnership' }
    : UseContractWriteConfig<
        typeof yBuzzUsernamesABI,
        'renounceOwnership',
        TMode
      > & {
        abi?: never
        functionName?: 'renounceOwnership'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzUsernamesABI, 'renounceOwnership', TMode>(
    {
      abi: yBuzzUsernamesABI,
      address: yBuzzUsernamesAddress,
      functionName: 'renounceOwnership',
      ...config,
    } as any,
  )
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function useYBuzzUsernamesTransferOwnership<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzUsernamesABI,
          'transferOwnership'
        >['request']['abi'],
        'transferOwnership',
        TMode
      > & { functionName?: 'transferOwnership' }
    : UseContractWriteConfig<
        typeof yBuzzUsernamesABI,
        'transferOwnership',
        TMode
      > & {
        abi?: never
        functionName?: 'transferOwnership'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzUsernamesABI, 'transferOwnership', TMode>(
    {
      abi: yBuzzUsernamesABI,
      address: yBuzzUsernamesAddress,
      functionName: 'transferOwnership',
      ...config,
    } as any,
  )
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `functionName` set to `"upgradeTo"`.
 */
export function useYBuzzUsernamesUpgradeTo<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzUsernamesABI,
          'upgradeTo'
        >['request']['abi'],
        'upgradeTo',
        TMode
      > & { functionName?: 'upgradeTo' }
    : UseContractWriteConfig<typeof yBuzzUsernamesABI, 'upgradeTo', TMode> & {
        abi?: never
        functionName?: 'upgradeTo'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzUsernamesABI, 'upgradeTo', TMode>({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    functionName: 'upgradeTo',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `functionName` set to `"upgradeToAndCall"`.
 */
export function useYBuzzUsernamesUpgradeToAndCall<
  TMode extends WriteContractMode = undefined,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof yBuzzUsernamesABI,
          'upgradeToAndCall'
        >['request']['abi'],
        'upgradeToAndCall',
        TMode
      > & { functionName?: 'upgradeToAndCall' }
    : UseContractWriteConfig<
        typeof yBuzzUsernamesABI,
        'upgradeToAndCall',
        TMode
      > & {
        abi?: never
        functionName?: 'upgradeToAndCall'
      } = {} as any,
) {
  return useContractWrite<typeof yBuzzUsernamesABI, 'upgradeToAndCall', TMode>({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    functionName: 'upgradeToAndCall',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzUsernamesABI}__.
 */
export function usePrepareYBuzzUsernamesWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzUsernamesABI, TFunctionName>,
    'abi' | 'address'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzUsernamesABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `functionName` set to `"initialize"`.
 */
export function usePrepareYBuzzUsernamesInitialize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzUsernamesABI, 'initialize'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    functionName: 'initialize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzUsernamesABI, 'initialize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `functionName` set to `"registerName"`.
 */
export function usePrepareYBuzzUsernamesRegisterName(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzUsernamesABI, 'registerName'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    functionName: 'registerName',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzUsernamesABI, 'registerName'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `functionName` set to `"registerNameAsOwner"`.
 */
export function usePrepareYBuzzUsernamesRegisterNameAsOwner(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof yBuzzUsernamesABI,
      'registerNameAsOwner'
    >,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    functionName: 'registerNameAsOwner',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof yBuzzUsernamesABI,
    'registerNameAsOwner'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `functionName` set to `"renounceOwnership"`.
 */
export function usePrepareYBuzzUsernamesRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof yBuzzUsernamesABI,
      'renounceOwnership'
    >,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof yBuzzUsernamesABI,
    'renounceOwnership'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `functionName` set to `"transferOwnership"`.
 */
export function usePrepareYBuzzUsernamesTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof yBuzzUsernamesABI,
      'transferOwnership'
    >,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof yBuzzUsernamesABI,
    'transferOwnership'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `functionName` set to `"upgradeTo"`.
 */
export function usePrepareYBuzzUsernamesUpgradeTo(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzUsernamesABI, 'upgradeTo'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    functionName: 'upgradeTo',
    ...config,
  } as UsePrepareContractWriteConfig<typeof yBuzzUsernamesABI, 'upgradeTo'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `functionName` set to `"upgradeToAndCall"`.
 */
export function usePrepareYBuzzUsernamesUpgradeToAndCall(
  config: Omit<
    UsePrepareContractWriteConfig<typeof yBuzzUsernamesABI, 'upgradeToAndCall'>,
    'abi' | 'address' | 'functionName'
  > = {} as any,
) {
  return usePrepareContractWrite({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    functionName: 'upgradeToAndCall',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof yBuzzUsernamesABI,
    'upgradeToAndCall'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzUsernamesABI}__.
 */
export function useYBuzzUsernamesEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof yBuzzUsernamesABI, TEventName>,
    'abi' | 'address'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    ...config,
  } as UseContractEventConfig<typeof yBuzzUsernamesABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `eventName` set to `"AdminChanged"`.
 */
export function useYBuzzUsernamesAdminChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzUsernamesABI, 'AdminChanged'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    eventName: 'AdminChanged',
    ...config,
  } as UseContractEventConfig<typeof yBuzzUsernamesABI, 'AdminChanged'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `eventName` set to `"BeaconUpgraded"`.
 */
export function useYBuzzUsernamesBeaconUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzUsernamesABI, 'BeaconUpgraded'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    eventName: 'BeaconUpgraded',
    ...config,
  } as UseContractEventConfig<typeof yBuzzUsernamesABI, 'BeaconUpgraded'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `eventName` set to `"Initialized"`.
 */
export function useYBuzzUsernamesInitializedEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzUsernamesABI, 'Initialized'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    eventName: 'Initialized',
    ...config,
  } as UseContractEventConfig<typeof yBuzzUsernamesABI, 'Initialized'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `eventName` set to `"NameRegistered"`.
 */
export function useYBuzzUsernamesNameRegisteredEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzUsernamesABI, 'NameRegistered'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    eventName: 'NameRegistered',
    ...config,
  } as UseContractEventConfig<typeof yBuzzUsernamesABI, 'NameRegistered'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `eventName` set to `"NameUnregistered"`.
 */
export function useYBuzzUsernamesNameUnregisteredEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzUsernamesABI, 'NameUnregistered'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    eventName: 'NameUnregistered',
    ...config,
  } as UseContractEventConfig<typeof yBuzzUsernamesABI, 'NameUnregistered'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `eventName` set to `"OwnershipTransferred"`.
 */
export function useYBuzzUsernamesOwnershipTransferredEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzUsernamesABI, 'OwnershipTransferred'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    eventName: 'OwnershipTransferred',
    ...config,
  } as UseContractEventConfig<typeof yBuzzUsernamesABI, 'OwnershipTransferred'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link yBuzzUsernamesABI}__ and `eventName` set to `"Upgraded"`.
 */
export function useYBuzzUsernamesUpgradedEvent(
  config: Omit<
    UseContractEventConfig<typeof yBuzzUsernamesABI, 'Upgraded'>,
    'abi' | 'address' | 'eventName'
  > = {} as any,
) {
  return useContractEvent({
    abi: yBuzzUsernamesABI,
    address: yBuzzUsernamesAddress,
    eventName: 'Upgraded',
    ...config,
  } as UseContractEventConfig<typeof yBuzzUsernamesABI, 'Upgraded'>)
}
