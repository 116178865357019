import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { Timeline } from "react-twitter-widgets";
import { ActionSheet, Button, ContentCard } from "../../components";
import {
  PostContext,
  SingleCommunityContext,
  UserContext,
  MememojiContext,
} from "../../context";
import { addressToColor, shortenAddress } from "../../utility";
import { ICreatePost, IMememoji, IPost } from "../../api/types";
import {
  useApproveMememojiReaction,
  useMememojiBalances,
  useMememojiReaction,
  useMintMememoji,
} from "../../hooks/chain/mememoji";
import { Address } from "wagmi";
import {
  LOCAL_STORAGE_KEY_COMMUNITIES_FAV,
  LOCAL_STORAGE_KEY_TABNAV_STATE_HOME,
} from "../Home/Home";
import { mememojiBlacklistConfig } from "../Mememoji/Mememoji";
import { maxUint256, zeroAddress } from "viem";
import { useCreatePost } from "../../hooks/chain/post";
import { FullScreenLoading } from "../../components/loading";
import { useLocation } from "react-router-dom";

const baseFilter = ["New", "Top", "Owner", "Your"];

const twitterProfiles = {
  ["0xD9653486f1805d94BfF719d4Df9f665feAb8B48a".toLowerCase()]: "EURO2024",
};

export const Community: React.FC = () => {
  const location = useLocation();
  const [tabnavState, setTabNavState] = useLocalStorage<string>(
    LOCAL_STORAGE_KEY_TABNAV_STATE_HOME,
    "",
  );
  const [favCommunityIds, setFavCommunityIds] = useLocalStorage<string[]>(
    LOCAL_STORAGE_KEY_COMMUNITIES_FAV,
    [],
  );
  const { user, userName } = useContext(UserContext)!;
  const {
    posts,
    isLoading: isLoadingPosts,
    error,
    refetchPostsByCommunityId,
  } = useContext(SingleCommunityContext)!;
  const { community, isLoading: isLoadingPost } = useContext(PostContext)!;
  const {
    createPost,
    isLoading: isLoadingCreatePost,
    isError: isErrorCreatePost,
    isSuccess: isSuccessCreatePost,
    error: errorCreatePost,
    createdPostId,
    postPrice,
  } = useCreatePost(community?.communityToken as Address);

  const onlyOwnerIsAllowedToPost = postPrice === 0n;
  const userIsCommunityOwner =
    community?.owner === user ||
    (community?.name === "EM2024" &&
      user === "0x85f8e95FdDE8c2633aeC7072349Ece260d385ceA");
  const filter =
    community?.communityToken &&
    twitterProfiles[community?.communityToken.toLowerCase()]?.length > 0
      ? [...baseFilter, "Twitter"]
      : baseFilter;

  const [filteredAndSortedPosts, setFilteredAndSortedPosts] = useState<
    IPost[] | null
  >(null);
  const [isLoadingAfterCreate, setIsLoadingAfterCreate] =
    useState<boolean>(false);
  const [activeFilter, setActiveFilter] = useState(filter[0]);
  const [showCreatePostForm, setShowCreatePostForm] = useState(false);
  const [premiumContentAmountSheetVisible, setPremiumContentAmountSheetVisible] = useState<boolean>(false);
  const [createPostAddPremiumContent, setCreatePostAddPremiumContent] = useState(false);
  const [premiumContentAmount, setPremiumContentAmount] = useState<number>(1);
  const [postTitle, setPostTitle] = useState<string>("");
  const [postTeaser, setPostTeaser] = useState<string>("");
  const [postPremium, setPostPremium] = useState<string>("");

  // MEMEmoji reaction
  const {
    mememojiReaction,
    mememojiReactionIsSuccess,
    mememojiReactionIsLoading,
  } = useMememojiReaction();

  const { isLoading, mememojis: availableMememojis } =
    useContext(MememojiContext)!;

  const [filteredMemojis, setFilteredMemojis] = useState<IMememoji[]>([]);
  const [sortedMememojis, setSortedMememojis] = useState<IMememoji[]>([]);
  const [selectedMememoji, setSelectedMememoji] = useState<IMememoji | null>(
    null,
  );

  const {
    balances,
    fetchBalances,
    isLoading: isLoadingBalances,
    isError: isErrorBalances,
  } = useMememojiBalances();

  const {
    mememojiData: mintMememojiData,
    mintMememoji,
    isSuccess: mintMememojiIsSuccess,
    isLoading: mintMememojiIsLoading,
  } = useMintMememoji();

  const { data: approveResult, approveMememojiReaction } =
    useApproveMememojiReaction();

  const resetMememojiReaction = (contentId: Address | null) => {
    setSelectedMememoji(null);
  };

  const mintReactionMememoji = async (
    selectedMememoji: IMememoji,
    amount: number,
  ) => {
    setSelectedMememoji(selectedMememoji);
    mintMememoji(selectedMememoji.address as Address, amount);
  };

  useEffect(() => {
    if (!isLoading && availableMememojis) {
      const filtered = availableMememojis?.filter(
        (memoji: IMememoji) =>
          !mememojiBlacklistConfig.includes(memoji.address.toLowerCase()),
      );

      setFilteredMemojis(filtered || []);
      fetchBalances(
        user,
        filtered?.map((memoji: IMememoji) => memoji.address as Address) || [],
      );
    }
  }, [availableMememojis, isLoading, fetchBalances, user]);

  useEffect(() => {
    if (
      !mintMememojiIsLoading &&
      mintMememojiIsSuccess &&
      mintMememojiData &&
      selectedMememoji
    ) {
      fetchBalances(
        user,
        availableMememojis?.map(
          (memoji: IMememoji) => memoji.address as Address,
        ) || [],
      );

      approveMememojiReaction(selectedMememoji.address as Address, maxUint256);
      setSelectedMememoji(null);
    }
  }, [
    mintMememojiIsLoading,
    mintMememojiIsSuccess,
    mintMememojiData,
    selectedMememoji,
    fetchBalances,
    user,
    availableMememojis,
    approveMememojiReaction,
  ]);

  useEffect(() => {
    if (filteredMemojis && balances) {
      const sorted = filteredMemojis.sort((a, b) => {
        if (balances[a.address] || balances[b.address]) {
          return balances[b.address] > balances[a.address] ? 1 : -1;
        }
        return 0;
      });
      setSortedMememojis(sorted);
    }
  }, [balances, filteredMemojis]);

  useEffect(() => {
    if (mememojiReactionIsSuccess) {
      resetMememojiReaction(null);
      setTimeout(() => {
        refetchPostsByCommunityId(community?.communityId as Address);
      }, 2500);
    }
  }, [
    community?.communityId,
    mememojiReactionIsSuccess,
    refetchPostsByCommunityId,
  ]);

  const sendMememojiReaction = async (
    contentId: Address,
    token: Address,
    amount: number = 1,
  ) => {
    mememojiReaction(contentId, token, amount);
  };

  // MEMEmoji end

  useEffect(() => {
    if (
      tabnavState !== location.pathname &&
      tabnavState.length > 0 &&
      tabnavState !== "community.back"
    ) {
      setTabNavState(location.pathname);
    }
  }, [location, setTabNavState, tabnavState]);

  useEffect(() => {
    if (posts) {
      const filteredPosts = posts.filter((post) => {
        if (activeFilter === "Your") {
          return post.author === user;
        }
        if (activeFilter === "Owner" && community) {
          return post.author === community.owner;
        }
        return true;
      });

      const sortedPosts = filteredPosts.sort((a, b) => {
        if (activeFilter === "Your") {
          return b.author === user ? 1 : -1;
        } else if (activeFilter === "Top") {
          return (b.numComments || 0) - (a.numComments || 0);
        }
        return (b.createdAt || 0) - (a.createdAt || 0);
      });
      setFilteredAndSortedPosts(sortedPosts);
    }
  }, [posts, activeFilter, user, community]);

  const resetForm = useCallback(() => {
    setShowCreatePostForm(false);
    setIsLoadingAfterCreate(false);
    refetchPostsByCommunityId(community?.communityId as Address);
    setPostTitle("");
    setPostTeaser("");
  }, [community, refetchPostsByCommunityId]);

  const sendPost = () => {
    const newPostData: ICreatePost = {
      author: user,
      community: community?.communityId as Address,
      title: postTitle,
      teaser: postTeaser,
      more: postPremium,
      morePaywallToken: zeroAddress,
      morePaywallAmount: 0n,
    };
    createPost(newPostData);
  };

  useEffect(() => {
    if (
      !isLoadingPosts &&
      !isLoadingPost &&
      !isLoadingCreatePost &&
      isSuccessCreatePost &&
      createdPostId
    ) {
      setIsLoadingAfterCreate(true);
      // it needs some time to index the newly created post
      setTimeout(() => {
        resetForm();
      }, 3500);
    }
  }, [
    isLoadingPosts,
    isLoadingPost,
    isLoadingCreatePost,
    isSuccessCreatePost,
    createdPostId,
    resetForm,
  ]);

  useEffect(() => {
    if (isErrorCreatePost) {
      setIsLoadingAfterCreate(false);
      console.error("Error creating post", errorCreatePost);
      // @todo: show error message and if not enought community tokens show a message to get some
    }
  }, [isErrorCreatePost]);

  return (
    <>
      <div className="flex flex-1 flex-col bg-no-repeat bg-cover bg-center bg-fixed bg-[#f8f8f8] h-full w-full">
        <div className="flex flex-1 flex-col px-4 pt-safe-or-4 h-full">
          <div className="flex flex-row justify-between">
            <Button
              size="xs"
              variant="help"
              className="w-10 h-10 shadow-lg"
              onClick={() => {
                setTabNavState("community.back");
                window.history.back();
              }}
            >
              <i className="material-symbols-rounded text-2xl">arrow_back</i>
            </Button>
            <div>
              <h1 className="text-2xl font-bold text-dark text-center leading-10">
                {community?.name}
              </h1>
            </div>
            <Button
              size="xs"
              variant="help"
              className="w-10 h-10 shadow-lg"
              onClick={() => {
                if (
                  favCommunityIds.includes(community?.communityId as string)
                ) {
                  setFavCommunityIds(
                    favCommunityIds.filter(
                      (communityId) =>
                        communityId !== (community?.communityId as string),
                    ),
                  );
                } else {
                  setFavCommunityIds([
                    ...favCommunityIds,
                    community?.communityId as string,
                  ]);
                }
              }}
            >
              <i
                className={
                  favCommunityIds.includes(community?.communityId as string)
                    ? "material-symbols-rounded material-symbols-rounded-filled text-2xl"
                    : "material-symbols-rounded material-symbols-rounded-outlined text-2xl"
                }
              >
                star
              </i>
            </Button>
          </div>

          {showCreatePostForm ? (
            <div className="bg-white rounded-2xl p-3 mt-28 shadow-lg">
              <div className="h-[50px] w-full object-left">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row py-1 px-1">
                    <div className="h-[40px] w-[40px] rounded-full text-center">
                      <span
                        className={`material-symbols-rounded text-5xl`}
                        style={{ color: addressToColor(user) }}
                      >
                        face
                      </span>
                    </div>
                    <div>
                      <div className="px-4 pt-1 text-sm font-bold">
                        {shortenAddress(user)}
                      </div>
                      <div className="px-4 text-xs">
                        You can create a post now
                      </div>
                    </div>
                  </div>
                  <Button
                    size="xs"
                    variant="help"
                    className="w-10 h-10 bg-[#eff3f9] rounded-full"
                    onClick={() => setShowCreatePostForm(false)}
                  >
                    <i className="material-symbols-rounded text-lg">close</i>
                  </Button>
                </div>
              </div>
              <div className="mt-4">
                <h1 className="text-sm font-bold">Title</h1>
                <input
                  type="text"
                  className="w-full h-10 mt-2 border border-[#c0c4cb] rounded-2xl px-4"
                  value={postTitle}
                  onChange={(e) => setPostTitle(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <p className="text-sm font-bold">Message</p>
                <textarea
                  name="teaser"
                  cols={40}
                  rows={5}
                  className="w-full h-40 mt-2 border border-[#c0c4cb] rounded-2xl p-4"
                  value={postTeaser}
                  onChange={(e) => setPostTeaser(e.target.value)}
                />
              </div>
              {!createPostAddPremiumContent && (<div className="mt-4">
                <p className="text-sm font-bold">
                  <a onClick={() => setCreatePostAddPremiumContent(true)}>➕ Add premium content</a>
                </p>
              </div>)}
              {createPostAddPremiumContent && (
                <div className="mt-4">
                  <p className="text-sm font-bold">Premium content</p>

                  <div className="flex flex-row py-2">
                    <Button
                      size="xs"
                      variant="help"
                      className="w-10 h-10 bg-[#eff3f9] rounded-full"
                      onClick={() => setPremiumContentAmount(premiumContentAmount - 1 < 1 ? 1 : premiumContentAmount - 1)}
                    >
                      <i className="material-symbols-rounded text-lg">remove</i>
                    </Button>

                    <a onClick={() => setPremiumContentAmountSheetVisible(true)}>
                      <div className="flex flex-col mx-4 text-center">
                        <span className="text-xs text-[#9aaac1]">Amount</span>
                        <span className="text-sm font-bold">{premiumContentAmount}</span>
                      </div>
                    </a>

                    <Button
                      size="xs"
                      variant="help"
                      className="w-10 h-10 bg-[#eff3f9] rounded-full"
                      onClick={() => setPremiumContentAmount(premiumContentAmount + 1)}
                    >
                      <i className="material-symbols-rounded text-lg">add</i>
                    </Button>
                  </div>

                  <textarea
                    name="more"
                    cols={40}
                    rows={5}
                    className="w-full h-40 mt-2 border border-[#c0c4cb] rounded-2xl p-4"
                    value={postPremium}
                    onChange={(e) => setPostPremium(e.target.value)}
                  />
                </div>
              )}
              <div className="flex flex-row mt-4 justify-end">
                <a onClick={() => sendPost()} className="cursor-pointer">
                  <p className="bg-[#F8B500]/50 pl-2 pr-4 py-1 rounded-2xl border-2 border-[#F8B500]">
                    <span className="text-md ml-1 mr-2">➕</span>
                    <span className="font-bold text-sm">Create</span>
                  </p>
                </a>
              </div>
            </div>
          ) : (
            <>
              <div className="mt-10 bg-[#f1f1f1] rounded-2xl h-12 px-4">
                <div className="flex flex-row mt-1 justify-between">
                  {filter.map((filterItem) => (
                    <a
                      onClick={() => setActiveFilter(filterItem)}
                      className={
                        filterItem === activeFilter
                          ? "h-8 w-[60px] leading-7 text-center bg-[#F8B500] rounded-xl text-white align-middle"
                          : "h-8 w-[60px] leading-7 text-center text-[#9aaac1]"
                      }
                      key={`filter-${filterItem}`}
                    >
                      <span className="text-xs text-center font-bold">
                        {filterItem}
                      </span>
                    </a>
                  ))}
                </div>
              </div>
              <div className="overflow-scroll h-full mt-2 pb-8">
                {activeFilter === "Twitter" && community?.communityToken ? (
                  <div
                    className="topBox flex-grow"
                    style={{
                      backgroundColor: "#ffffff",
                      backgroundImage: "url(/twitter.svg)",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      boxShadow:
                        "rgba(88, 102, 126, 0.08) 0px 4px 24px, rgba(88, 102, 126, 0.12) 0px 1px 2px",
                      borderRadius: "12px",
                      height: "100%",
                      marginBottom: "1rem",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <Timeline
                      dataSource={{
                        sourceType: "profile",
                        screenName:
                          twitterProfiles[
                            community?.communityToken.toLowerCase()
                          ],
                      }}
                      options={{
                        chrome: "noheader, nofooter, noborders, noscrollbar",
                        limit: 5,
                        height: "650",
                      }}
                    />
                  </div>
                ) : (
                  filteredAndSortedPosts?.map((content) => (
                    <ContentCard
                      key={`content-${content.postId}`}
                      variant="light"
                      content={{
                        ...content,
                        teaser:
                          (content.teaser || "").length > 140
                            ? (content.teaser || "").substring(0, 140) + "..."
                            : content.teaser,
                      }}
                      balances={balances}
                      mememojis={sortedMememojis}
                      sendMememojiReaction={sendMememojiReaction}
                      mintMememoji={mintReactionMememoji}
                      onComment={() => {}}
                    />
                  ))
                )}
              </div>
            </>
          )}
          {(!onlyOwnerIsAllowedToPost || userIsCommunityOwner) && (
            <div
              className="cursor-pointer absolute bottom-20 right-4 h-16 w-16 bg-[#F8B500] rounded-full content-center text-center text-3xl font-bold text-white shadow-xl"
              onClick={() => setShowCreatePostForm(true)}
            >
              +
            </div>
          )}
        </div>
      </div>
      {(isLoadingAfterCreate ||
        isLoadingPost ||
        isLoadingCreatePost ||
        mememojiReactionIsLoading) && <FullScreenLoading />}
      <ActionSheet
        title="Enter an amount"
        isVisible={premiumContentAmountSheetVisible}
        onClose={() => setPremiumContentAmountSheetVisible(false)}
        className="bg-[#f8f8f8] text-center"
      >
        <input
          type="number"
          value={premiumContentAmount}
          onChange={(e) => setPremiumContentAmount(parseInt(e.target.value || '1'))}
          className="w-full h-10 mt-2 border border-[#c0c4cb] rounded-2xl px-4"
        />
        <Button
          onClick={() => setPremiumContentAmountSheetVisible(false)}
          className="mt-4"
        >
          Continue
        </Button>
      </ActionSheet>
    </>
  );
};
