import {
  useYBuzzCoreCommunities,
  useYBuzzCoreCreateComment,
} from "../../../generated.ts";
import { ICreateComment } from "../../../api/types.ts";
import { useState, useCallback, useEffect } from "react";
import { useWaitForTransaction } from "wagmi";
import { zeroAddress } from "viem";

const initialCreateCommentData: ICreateComment = {
  community: zeroAddress,
  postId: zeroAddress,
  parentContentId: zeroAddress,
  teaser: "",
  more: "",
  morePaywallToken: zeroAddress,
  morePaywallAmount: 1n,
  author: zeroAddress,
};

export const useCreateComment = () => {
  const [createCommentData, setCreateCommentData] = useState<ICreateComment>(
    initialCreateCommentData,
  );

  const { community, postId, parentContentId, teaser, more, morePaywallToken, morePaywallAmount } =
    createCommentData;

  const {
    data: communityData,
    refetch: refetchCommunities,
    isLoading: isLoadingCommunities,
    isSuccess: isSuccessLoadingCommunities,
  } = useYBuzzCoreCommunities({
    args: [community],
    enabled: community !== zeroAddress,
  });

  const commentPrice =
    (communityData && communityData.length > 4 && communityData[4]) || 0n;

  const {
    writeAsync: executeCreateComment,
    data: commentCreatedData,
    error: createCommentWriteError,
    isError: createCommentIsError,
    isLoading: createCommentIsLoading,
    isSuccess: createCommentIsSuccess,
    reset: resetCreateComment,
  } = useYBuzzCoreCreateComment();

  const {
    isSuccess: waitForTransactionIsSuccess,
    isLoading: waitForTransactionIsLoading,
    refetch: refetchWaitForTransaction,
  } = useWaitForTransaction({
    hash: commentCreatedData?.hash,
    enabled:
      !createCommentIsLoading &&
      createCommentIsSuccess &&
      commentCreatedData !== undefined,
  });

  const createComment = useCallback((newCreateCommentData: ICreateComment) => {
    setCreateCommentData(newCreateCommentData);
  }, []);

  const reset = useCallback(() => {
    setTimeout(() => {
      resetCreateComment();
    }, 2000);
    setCreateCommentData(initialCreateCommentData);
  }, [resetCreateComment]);

  useEffect(() => {
    if (
      executeCreateComment &&
      community !== zeroAddress &&
      teaser != "" &&
      postId != zeroAddress &&
      parentContentId != zeroAddress &&
      !createCommentIsLoading &&
      !isLoadingCommunities &&
      isSuccessLoadingCommunities &&
      !createCommentIsSuccess &&
      !waitForTransactionIsLoading &&
      !waitForTransactionIsSuccess &&
      commentCreatedData === undefined
    ) {
      executeCreateComment({
        args: [community, postId, parentContentId, teaser, more, morePaywallToken, morePaywallAmount],
        value: commentPrice,
      }).then();
    }
  }, [
    executeCreateComment,
    community,
    postId,
    parentContentId,
    teaser,
    more,
    morePaywallToken,
    morePaywallAmount,
    createCommentIsLoading,
    createCommentIsSuccess,
    isLoadingCommunities,
    isSuccessLoadingCommunities,
    commentPrice,
    waitForTransactionIsLoading,
    waitForTransactionIsSuccess,
    commentCreatedData,
  ]);

  useEffect(() => {
    if (createCommentIsSuccess && commentCreatedData) {
      refetchWaitForTransaction().then();
    }

    if (waitForTransactionIsSuccess || createCommentIsError) {
      reset();
    }
  }, [
    createCommentData,
    createCommentIsSuccess,
    createCommentIsError,
    commentCreatedData,
    reset,
    waitForTransactionIsSuccess,
    refetchWaitForTransaction,
  ]);

  return {
    createdCommentId: commentCreatedData ? commentCreatedData.hash : null,
    isLoading: createCommentIsLoading || waitForTransactionIsLoading,
    isSuccess: createCommentIsSuccess && waitForTransactionIsSuccess,
    createComment,
    isError: createCommentIsError,
    error: createCommentWriteError,
  };
};
